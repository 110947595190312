<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    CLASSES &nbsp; <v-chip color="yellow">{{classCount}}</v-chip>
                </v-card-title>
                <v-card-subtitle>
                    Manage Classes, Add / Update / Remove Classes
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <!-- Create /Update Form -->
                         <v-col cols="12" v-if="newform">
                            <v-form v-model="valid" ref="form">
                                <v-container>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="form.school_arm_id"
                                                outlined
                                                :items="schoolarms"
                                                item-text="type"
                                                item-value="school_arm_id"
                                                :rules="[v => !!v || 'School Arm is required']"
                                                label="Select School Arm"
                                                required
                                            ></v-select>
                                        </v-col>                                        
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.class"
                                                label="Class Name"
                                                :rules="[v => !!v || 'Class Name is required']"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.teacher_name"
                                                label="Class Teacher Name"
                                            ></v-text-field>
                                        </v-col>                                         
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="postRecord" v-if="!editBtn"
                                        >
                                            Create
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="updateRecord" v-else
                                        >
                                            Update
                                        </v-btn>
                                        <v-btn tile text color="secondary" @click="newform = false">
                                            <v-icon left>mdi-back</v-icon>
                                            Go Back
                                        </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                        <!-- Records -->
                        <v-col cols="12" v-else>
                            <info-loader-2 v-if="fetchingContent"/>
                            <v-row class="mb-5" v-else>
                                <v-col cols="12">
                                    <v-alert
                                        type="info"
                                        dense
                                        border="left"
                                        text
                                        color="orange"
                                        >
                                        <marquee>
                                            <span v-for="(info,i) in infos" :key="i" class="black--text mr-5">
                                            {{info.content}} | &nbsp;
                                            </span>
                                        </marquee>
                                    </v-alert>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3" v-for="(record, i) in records" :key="i">
                                    <v-card elevation="2" color="#F5F5F5">
                                        <v-card-title>
                                            {{record.class}}
                                        </v-card-title>
                                        <v-card-subtitle>{{record.school_arm.type}} | {{record.teacher_name}}</v-card-subtitle>
                                        <v-card-actions>
                                            <v-btn @click="editRecord(record)" fab light x-small
                                            ><v-icon dark> mdi-pencil </v-icon>
                                            </v-btn>
                                            <v-btn v-if="record.has_students == 0" @click="deleteRecord(record)" fab color="error" x-small
                                            ><v-icon dark> mdi-trash-can-outline </v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-btn tile text color="success" @click="reset">
                                Add New Class
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import HeaderVue from '../../components/Header.vue'
import InfoLoader2 from '../../components/InfoLoader2.vue';
  export default {
    name: 'Classes',
    components: {
        HeaderVue,
        InfoLoader2
    },
    mounted() {
       if (!this.authToken) {
           this.$router.push('login')
       }
       this.getSchoolArms()
       this.getRecords()
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        newform: false,
        editBtn: false,
        form:{},
        records:[],
        schoolarms:[],
        valid: true,
        fetchingContent:true,
        infos:[
            {content: 'Class names cannot be changed once saved, so be sure to enter the right class name according to how it is named in your school'},
            {content: 'The reason why we do not allow class name updates is so that users do not muddle up existing class names with vague names'},
            {content: 'In rare cases when you make an error and you need to update it, then you can reach out to us'},
        ],
    }),
    computed:{
        classCount(){
            return this.records.length
        }
    },
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        reset () {
            this.newform = true
            this.editBtn = false
            this.form = {}
        },
        getSchoolArms () {
            axios.get(process.env.VUE_APP_BASEURL+'/schoolarms',
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.schoolarms =  response.data.data            
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
        },
        getRecords () {
            axios.get(process.env.VUE_APP_BASEURL+'/classes?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.records =  response.data.data  
                this.fetchingContent = false;          
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'APP is temporarily unavailable. Wait or Contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        },
        postRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/classes?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        editRecord (record){
            this.newform = true
            this.form.id = record.class_id
            this.form.teacher_name = record.teacher_name
            this.form.class = record.class
            this.form.school_arm_id = record.school_arm.school_arm_id
            this.editBtn = true
        },
        updateRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/classes/'+this.form.id+'?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.editBtn = false             
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        deleteRecord (record) {
            if(confirm("Do you really want to delete?")){
                let loader = this.$loading.show()
                axios.delete(process.env.VUE_APP_BASEURL+'/classes/'+record.class_id+'?school_id='+this.school.school_id+'&confirm=DELETE',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then(response => {
                     this.$toast.success(response.data.message, {
                        timeout: 5000
                    }); 
                    this.getRecords()
                })
                .catch(error => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            }
        }
    },
  }
</script>
